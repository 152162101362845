<template>
  <div class="tw-relative tw--mt-7 itinerary-list">
    <div class="floating-wrapper" v-if="hasDestination && hasDate">
      <v-btn
        dark
        fab
        color="#E6FFA1"
        @click="openSubmenuItem('TripItineraryCreate', events.TRAVEL)"
      >
        <v-icon color="#203848">mdi-plus</v-icon>
      </v-btn>
    </div>
    <p class="j-text-field-label tw-text-18 tw-font-bold tw-mt-6 tw-mb-0">
      Book flights & Car Rentals
    </p>
    <p class="tw-text-left tw-text-xs tw-text-light-grey tw-mb-4">
      We earn a small amount when you buy through our links
    </p>
    <div class="tw-flex tw-justify-between">
      <!-- <a
        href="https://www.tkqlhce.com/click-101084691-15736720"
        target="_blank"
        rel="noopener noreferrer"
        class="tw-w-1/2 tw-mr-2"
      >
        <button
          class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
          type="button"
        >
          <span class="tw-text-left tw-text-dark-green tw-font-bold"
            >Hopper</span
          >
          <img
            class="tw-rounded-full"
            src="@/assets/svgs/arrow-right-dark-background.svg"
            alt="Go"
          />
        </button>
      </a> -->
      <a
        href="https://prf.hn/click/camref:1101l3BN3k/pubref:letsjetty/ar:app"
        target="_blank"
        rel="noopener noreferrer"
        class="tw-w-full"
      >
        <button
          class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
          type="button"
        >
          <span class="tw-text-left tw-text-dark-green tw-font-bold"
            >Expedia</span
          >
          <img
            class="tw-rounded-full"
            src="@/assets/svgs/arrow-right-dark-background.svg"
            alt="Go"
          />
        </button>
      </a>
    </div>
    <div
      class="tw-flex tw-items-center tw-justify-center"
      v-if="!hasDestination || !hasDate"
    >
      <p class="tw-text-light-grey tw-py-4">
        Once both dates and destination for your trip are set, come back & start
        building your travel schedule here.
      </p>
    </div>
    <div v-else-if="hasDestination && hasDate && travelList.length === 0">
      <p class="tw-text-light-grey tw-py-4">
        Start building your travel schedule by adding a travel event.
      </p>
    </div>
    <div class="itinerary-wrapper">
      <template v-for="(event, i) in travelList">
        <p
          :key="event.id"
          class="tw-mt-12 tw-uppercase tw-text-left tw-font-semibold"
          v-if="
            i === 0 ||
            getChipDateText(event.startDate) !==
              getChipDateText(travelList[i - 1].startDate)
          "
        >
          {{ getChipDateText(event.startDate) }}
        </p>
        <EventPost
          :event="event"
          :is-trip-owner="isTripOwner"
          :user-id="sessionUser.id"
          :key="event.uuid"
          @delete="toggleDialog($event)"
        />
      </template>
    </div>
    <DeleteEventDialog
      :is-visible="isVisible"
      :event="eventToDelete"
      @cancel="toggleDialog"
      @close="toggleDialog"
    />
  </div>
</template>
<script>
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import EventPost from "@/components/itinerary/EventPost.vue";
import DeleteEventDialog from "@/components/itinerary/DeleteEventDialog.vue";
import { EVENTS } from "@/enums/events.js";

export default {
  name: "TravelSchedulesList",
  mixins: [FormattedDateRange],
  components: {
    EventPost,
    DeleteEventDialog
  },
  data() {
    return {
      eventToDelete: null,
      isVisible: false,
      travelList: [],
      itinerary: [],
      events: EVENTS
    };
  },
  computed: {
    sessionUser() {
      return this.$store.state.auth.user;
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    isTripOwner() {
      return this.trip && this.trip.ownerId === this.sessionUser.id;
    },
    hasDestination() {
      return (
        this.trip && this.trip.destinations && this.trip.destinations.length > 0
      );
    },
    hasDate() {
      return this.trip.startDate && this.trip.endDate;
    }
  },
  methods: {
    parseTime(timeString) {
      const [time, period] = timeString.split(" ");
      const [hours, minutes] = time.split(":").map(Number);
      let hours24 = hours % 12;
      if (period === "PM") {
        hours24 += 12;
      }
      return hours24 * 60 + minutes; // Convert time to minutes since midnight
    },
    openSubmenuItem(routeName, type) {
      this.$router.push({
        name: routeName,
        params: { value: type, activeDate: this.activeDate }
      });
    },
    async getItinerary() {
      const itineraryRes = await this.$store.dispatch("itinerary/find", {
        query: {
          tripId: this.$route.params.id
        }
      });
      if (itineraryRes.data)
        this.travelList = itineraryRes.data.filter(
          (item) => item.type === this.events.TRAVEL
        );

      this.travelList.sort((a, b) => {
        const dateComparison = a.startDate.localeCompare(b.startDate);
        if (dateComparison !== 0) {
          return dateComparison; // Sort by date first
        }

        const timeA = this.parseTime(a.startTime);
        const timeB = this.parseTime(b.startTime);
        return timeA - timeB;
      });
    },
    toggleDialog(event) {
      this.eventToDelete = event || null;
      this.isVisible = !this.isVisible;
      if (!event) {
        this.$store.commit(
          "itinerary/clearAll",
          this.$store.state["itinerary"]
        );
        this.getItinerary();
      }
    }
  },
  async beforeMount() {
    sessionStorage.removeItem("itinerary");
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "TRAVEL SCHEDULE",
      bgColor: "#E6FFA1",
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: true,
      tripHubButtonId: this.$route.params.id
    });

    if (!this.trip) {
      await this.$store.dispatch("trip/get", [
        this.$route.params.id,
        {
          query: {
            include: "destination,users,survey,trip_invitation"
          }
        }
      ]);
    }

    this.getItinerary();
  },
  destroyed() {
    this.$store.commit("itinerary/clearAll", this.$store.state["itinerary"]);
  }
};
</script>
